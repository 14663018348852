import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Group as GroupIcon,
  Summarize as SummarizeIcon,
  Report as ReportIcon,
  Campaign as CampaignIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Gamepad as GamepadIcon,
  Widgets as WidgetsIcon,
  Settings as SettingsIcon,
  InsertInvitation as InsertInvitationIcon,
  Dashboard,
  Key as KeyIcon,
  AddAPhoto,
} from "@mui/icons-material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ProfileMenu from "../profilemenu/profileMenu";
import { useAppStore } from "../../appStore";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import MessageIcon from "@mui/icons-material/Message";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { base_url } from "../../apiUtils";
import axios from "axios";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import QuickLinksMenu from "./quickLinks";

const drawerWidth = 290;

const Sidebar = (props) => {
  const { window } = props;
  const navigate = useNavigate();
  const {
    profileData,
    userPermissions,
    walletBalance,
    setWalletBalance,
    setCreditLimit,
  } = useAppStore();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleCopy = () => {
    navigator.clipboard.writeText(profileData?.userId || "");
    toast.success("user id copied.");
  };

  const toggleSubItems = (index) => {
    setSelectedIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const getIndex = () => {
    const path = location.pathname;

    switch (path) {
      case "/commissionsettings":
        return 3;
      case "/walletsettings":
        return 3;
      case "/wallet":
        return 4;
      case "/depositrequest":
        return 4;
      case "/withdrawrequest":
        return 4;
      case "/addGame":
        return 5;
      case "/preclosing-time-list":
        return 5;
      case "/edit-games":
        return 5;
      case "/playGame":
        return 5;
      case "/addMenu":
        return 6;
      case "/menu-formation":
        return 6;
      case "/winning-ratio-settings":
        return 6;
      case "/selectMenu":
        return 6;
      case "/accounts":
        return 10;
      case "/hierarchy-syso-accounts":
        return 10;
      case "/lower-game-play-details":
        return 3;
      case "/wallet-statements":
        return 11;
      case "/game-wallet-statements":
        return 11;

      // Add more cases here as needed
      default:
        return -1; // Return -1 if no match is found
    }
  };

  let userManagementItems = [
    { path: "/dashboard", text: "Dashboard", icon: <Dashboard /> },
    { path: "/permissions", text: "Permissions", icon: <KeyIcon /> },
    { path: "/users", text: "Users", icon: <GroupIcon /> },
    profileData?.role === "systemowner" && {
      text: "Settings",
      icon: <SettingsIcon />,
      subItems: [
        { path: "/commissionsettings", text: "Commission Settings" },
        { path: "/walletsettings", text: "Common Wallet Settings" },
      ],
    },

    {
      text: "Wallet",
      icon: <AccountBalanceWalletIcon />,
      // path: "/wallet",
      subItems: [
        { path: "/wallet", text: "Wallet Details" },
        { path: "/depositrequest", text: "Deposit Request" },
        { path: "/withdrawrequest", text: "Withdraw Request" },
      ],
    },

    {
      text: "Game",
      icon: <GamepadIcon />,
      subItems: [
        profileData?.role === "systemowner" && {
          path: "/addGame",
          text: "Add Game",
        },
        profileData?.role === "systemowner" && {
          path: "/preclosing-time-list",
          text: "Custom Preclosing",
        },
        profileData?.role === "systemowner" && {
          path: "/edit-games",
          text: "Edit Game",
        },
        profileData?.role !== "systemowner" && {
          path: "/playGame",
          text: "Play Game",
        },
      ].filter(Boolean),
    },
    {
      text: "Menu",
      icon: <WidgetsIcon />,
      subItems: [
        profileData?.role === "systemowner" && {
          path: "/addMenu",
          text: "Create Menu",
        },
        profileData?.role === "systemowner" && {
          path: "/menu-formation",
          text: "Menu / Ratio",
        },
        profileData?.role === "systemowner" && {
          path: "/winning-ratio-settings",
          text: "Special Case Ratio",
        },
        { path: "/selectMenu", text: "Choose Menu" },
      ].filter(Boolean),
    },
    { path: "/reports", text: "Reports", icon: <AssessmentIcon /> },
    { path: "/result-declaration", text: "Results", icon: <SummarizeIcon /> },

    { path: "/place-bets", text: "Place Bets", icon: <LibraryAddIcon /> },
    {
      text: "Accounts",
      icon: <AccountBoxIcon />,
      subItems: [
        { path: "/accounts", text: "Direct Accounts" },
        profileData?.role === "systemowner" && {
          path: "/hierarchy-syso-accounts",
          text: "Hierarchy Accounts",
        },
      ],
    },
    {
      text: "Transactions",
      icon: <ReceiptLongIcon />,
      subItems: [
        { path: "/lower-game-play-details", text: "CB Report" },
        {
          path: "/wallet-statements",
          text: "Wallet Statements",
        },
        {
          path: "/game-wallet-statements",
          text: "Game Statements",
        },
      ],
    },
    { path: "/annoucements", text: "Announcements", icon: <CampaignIcon /> },
    { path: "/send-message", text: "Message-Users", icon: <MessageIcon /> },
    {
      path: "/declare-holiday",
      text: "Holiday",
      icon: <InsertInvitationIcon />,
    },
    {
      path: "/send-photo",
      text: "Send Photo",
      icon: <AddAPhoto />,
    },
    {
      path: "/recieved-photos",
      text: "Recieved Photo",
      icon: <AddAPhoto />,
    },
  ].filter(Boolean);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const handleOpenLogoutDialog = () => {
    setIsLogoutDialogOpen(true);
  };

  const handleCloseLogoutDialog = () => {
    setIsLogoutDialogOpen(false);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    sessionStorage.removeItem("sessionId");
    navigate("/");
    setIsLogoutDialogOpen(false);
  };

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    let index;
    index = userManagementItems.findIndex(
      (item) => item.path === location.pathname
    );
    if (index == -1) {
      index = getIndex();
    }
    setSelectedIndex(index);
  }, [location.pathname]);

  // Filter menu items based on userPermissions
  const filteredItems = userManagementItems
    .map((item) => {
      if (!item.subItems) {
        // No subitems, check permission directly
        if (userPermissions && userPermissions.length > 0) {
          const permission = userPermissions.find(
            (perm) => perm.path === item.path && perm.access
          );
          return permission ? item : null;
        }
        if (profileData?.role === "systemowner") {
          return item; // Show all items for system owner
        }
      } else {
        // Has subitems, filter subitems based on permissions
        const filteredSubItems = item.subItems
          .map((subItem) => {
            if (userPermissions && userPermissions.length > 0) {
              const permission = userPermissions.find(
                (perm) => perm.path === subItem.path && perm.access
              );
              return permission ? subItem : null;
            }
            if (profileData?.role === "systemowner") {
              return subItem; // Show all subitems for system owner
            }
          })
          .filter(Boolean);

        if (filteredSubItems.length > 0) {
          return { ...item, subItems: filteredSubItems };
        }
        // return null; // If no subitems are visible, hide the main item as well
      }
    })
    .filter(Boolean);

  useEffect(() => {
    // Navigate to welcome if no items are visible
    if (filteredItems.length === 0) {
      navigate("/welcome");
    }
  }, []);

  let tokenStr = sessionStorage.getItem("accesstoken");

  const getWalletPoints = async () => {
    const response = await axios.get(`${base_url}wallet/balance`, {
      headers: { accesstoken: tokenStr },
    });
    const request = response.data;
    setWalletBalance(request?.walletPoint?.balance);
    setCreditLimit(request?.creditLimit);
    return response.data;
  };

  useEffect(() => {
    getWalletPoints();
    // fetchMessages();
  }, []);

  userManagementItems = filteredItems;

  const drawer = (
    <div
      style={{
        backgroundColor: "#cfd8dc",
        height: "100vh",
        backdropFilter: "blur(10px)",
      }}
    >
      <div
        style={{
          backgroundColor: "#880e4f",
          backdropFilter: "blur(10px)",
          height: "150px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Vertically center
          alignItems: "center", // Horizontally center
          textAlign: "center", // Ensure the text is centered
        }}
      >
        <img
          src="/images/sm-logo.png"
          alt="Logo"
          style={{
            height: "80px",
            width: "80px",
            filter: "contrast(150%)",
          }}
        />
        <Typography
          sx={{
            color: "#fbc02d",
            mt: "15px",
            letterSpacing: 1,
            fontSize: "20px",
          }}
        >
          SM-India
        </Typography>
      </div>

      <Box mt={1}>
        <List sx={{ m: "0px 28px" }}>
          {userManagementItems?.map((item, index) => (
            <React.Fragment key={item.text}>
              <Link
                to={item.path}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "block", // Ensures the link takes up the entire ListItem space
                }}
              >
                <ListItem
                  sx={{
                    m: 0.5,
                    p: "2px 10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor:
                      selectedIndex === index ? "rgba(255, 255, 255, 0.5)" : "",
                    backdropFilter: "blur(10px)",
                    color: selectedIndex === index ? "#c51162" : undefined,
                    borderRadius: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => toggleSubItems(index)}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ListItemIcon
                      sx={{
                        color: selectedIndex === index ? "#c51162" : "#c51162",
                        mr: -2,
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      selected={selectedIndex === index}
                      sx={{
                        whiteSpace: "nowrap",
                        fontWeight: "500 !important",
                      }}
                    />
                  </Box>
                  {item.subItems ? (
                    <Box sx={{ alignItems: "flex-end", cursor: "pointer" }}>
                      {selectedIndex === index ? (
                        <ExpandLessIcon
                          sx={{
                            color:
                              selectedIndex === index ? "#c51162" : "#c51162",
                            pt: 0.5,
                          }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          sx={{
                            color:
                              selectedIndex === index ? "#c51162" : "#c51162",
                            pt: 0.5,
                          }}
                        />
                      )}
                    </Box>
                  ) : null}
                </ListItem>
              </Link>
              {item.subItems && selectedIndex === index && (
                <List>
                  {item.subItems.map((subItem, subIndex) => (
                    <ListItem key={subItem.text} sx={{ p: "1px 0px" }}>
                      <Link
                        to={subItem.path}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ListItemIcon>{subItem.icon}</ListItemIcon>
                          <ListItemText
                            primary={subItem.text}
                            selected={selectedIndex === subIndex}
                            sx={{
                              whiteSpace: "nowrap",
                              fontWeight: "500 !important",
                            }}
                          />
                        </Box>
                      </Link>
                    </ListItem>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
        <Box>
          <Typography
            sx={{
              color: "red",
              textAlign: "center",
              fontSize: "0.6rem",
              my: 2,
            }}
          >
            {/* v_1.0 & 10-11-24  */}
            {/* v_1.0.1 & 27-11-24 */}
            v_1.0.2 & 29-11-24
          </Typography>
        </Box>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1,
          backgroundColor: "#FFFFFF",
          p: 0.5,
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { lg: "none" },
            }}
          >
            <MenuIcon sx={{ color: "orange" }} />{" "}
            {/* Set the icon color to gray */}
          </IconButton>
          <Grid container>
            <Grid item xs={0} sm={2}></Grid>
            <Grid item xs={12} sm={10}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "space-between", md: "space-around" },

                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: { xs: "12px", sm: "16px" },
                      display: { xs: "grid", md: "flex" },
                      ml: { xs: 0, sm: 5, md: 10 },
                    }}
                  >
                    User Id:{" "}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#1782FF",
                      fontWeight: "bold",
                      fontSize: { xs: "14px", sm: "16px" },
                      ml: 1,
                    }}
                  >
                    {profileData?.userId}
                  </Typography>
                  <Tooltip title="Copy user id">
                    <IconButton onClick={handleCopy}>
                      <ContentCopyIcon
                        sx={{ color: "#f57c00", fontSize: "16px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                {profileData?.role !== "systemowner" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: { xs: "12px", sm: "16px" },
                        display: { xs: "grid", md: "flex" },
                        ml: { xs: 0, sm: 5, md: 10 },
                      }}
                    >
                      Available Points:{" "}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#e53935",
                        fontWeight: "bold",
                        fontSize: { xs: "14px", sm: "16px" },
                        ml: 1,
                      }}
                    >
                      {Number(walletBalance).toFixed(2)}
                    </Typography>
                  </Box>
                )}

                <Box flexGrow={1} />
                {/* quick links */}
                {profileData?.role === "systemowner" && <QuickLinksMenu />}
                <ProfileMenu />
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
      >
        {/* Temporary Drawer for screens smaller than lg */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          {drawer}
        </Drawer>

        {/* Permanent Drawer for lg screens and above */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          mt: 8,
        }}
      >
        <Toolbar />
        {props.children}
      </Box>

      <Dialog open={isLogoutDialogOpen} onClose={handleCloseLogoutDialog}>
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Sidebar;
